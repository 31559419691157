import { API_ADMIN_URL } from "../../config/constants";
import { postRequest,fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : This function is used for get all veterinary service list
* By            : Afsar Ali
* Date          : 11-05-2024
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/veterinary/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : addEditData
* Description   : This function is used for Add/Edit veterinary services
* By            : Afsar Ali
* Date          : 11-05-2024
*********************************************************/
export const addEditData = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/veterinary/addeditdata`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : chnageStatus
* Description   : This function is used for chnage status of veterinary service
* By            : Afsar Ali
* Date          : 11-05-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/veterinary/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function
/*********************************************************
 * Function Name : blukUpload
 * Description   : This function is used for veterinary bulk upload
 * By            : Afsar Ali
 * Date          : 08-08-2024
 *********************************************************/
export const blukUpload = async (options) => {
    try {
      const params = {
        url: `${API_ADMIN_URL}servicesmaster/veterinary/bluk-upload`,
        postData: options,
      };
      const res = await postRequest(params);
      if (res.status === true || res.status === 200) {
        return { status: true, result: res?.data?.response?.result };
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function