import { API_ADMIN_URL } from "../../config/constants";
import { deleteRequest, postRequest } from "../API";

/*********************************************************
 * Function Name : List
 * Description   : Get list of all promocode list
 * By            : Afsar Ali
 * Date          : 02-04-2024
 *********************************************************/
export const list = async (options) => {
  try {
    const params = {
      url: `${API_ADMIN_URL}cms/breeds/list`,
      postData: options,
    };
    const res = await postRequest(params);
    if (res.status === true || res.status === 200) {
      return {
        status: true,
        result: res?.data?.response?.result,
        count: res?.data?.response?.count,
      };
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: "Under Maintenance, Please try after some time.",
    };
  }
}; //End of Function

/*********************************************************
 * Function Name : addeditdata
 * Description   : this function is used for Add/Edit promo code
 * By            : Afsar Ali
 * Date          : 02-04-2024
 *********************************************************/
export const addeditdata = async (options) => {
  try {
    const params = {
      url: `${API_ADMIN_URL}cms/breeds/addeditdata`,
      postData: options,
    };
    const res = await postRequest(params);
    if (res.status === true || res.status === 200) {
      return { status: true, result: res?.data?.response?.result };
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return {
      status: false,
      message: "Under Maintenance, Please try after some time.",
    };
  }
}; //End of Function

/*********************************************************
 * Function Name : chnageStatus
 * Description   : This function is used for chnage prmomo code status
 * By            : Afsar Ali
 * Date          : 02-04-2024
 *********************************************************/
export const chnageStatus = async (options) => {
  try {
    const params = {
      url: `${API_ADMIN_URL}cms/breeds/change-status`,
      postData: {
        ...options,
        ipAddress: sessionStorage.getItem("IP_ADDRESS"),
      },
    };
    const res = await postRequest(params);
    if (res.status === true || res.status === 200) {
      return { status: true, result: res?.data?.response?.result };
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return {
      status: false,
      message: "Under Maintenance, Please try after some time.",
    };
  }
}; //End of Function

/*********************************************************
 * Function Name : delete data
 * Description   : This function is used for chnage prmomo code status
 * By            : Afsar Ali
 * Date          : 02-04-2024
 *********************************************************/
export const deleteBreeds = async (options) => {
  try {
    const params = {
      url: `${API_ADMIN_URL}cms/breeds/delete`,
      postData: options,
    };
    const res = await postRequest(params);
    if (res.status === true || res.status === 200) {
      return { status: true, result: res?.data?.response?.result };
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return {
      status: false,
      message: "Under Maintenance, Please try after some time.",
    };
  }
}; //End of Function