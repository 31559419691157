import React, { useEffect } from "react";
import "./App.css";


import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { fetchIpAddress } from "./controllers/API"

import LoginPage from "./components/LoginPage/LoginPage.js";
import ResetPassword from "./components/LoginPage/ResetPassword.js";

import SubAdminList from './pages/Accounts/SubAdmin/List.js'
import SubAdminAddEditData from './pages/Accounts/SubAdmin/AddEditData.js'

import Sidebar from "./components/SideBar/Sidebar.jsx";
import Dashboard from "./pages/DashBaord/Dashboard.jsx";

import UserList from "./pages/Accounts/UserList/UserList.js";
import UserListEditPage from "./pages/Accounts/UserList/UserListEditPage.js";

import VenoderList from "./pages/Accounts/VendorList/VendorList.js";
import VendorListEditPage from "./pages/Accounts/VendorList/VenoderAddEditData.js";

import StoreList from "./pages/Accounts/VendorList/StoreList.js";
import StoreAddEditPage from "./pages/Accounts/VendorList/StoreAddEditData.js";

import BranchList from "./pages/Accounts/VendorList/BranchList.js";
import Notifications from "./pages/Notification/List.js";
import CategoriesProductList from "./pages/Category/Product List/ProductList.js";
import CategoriesServiceList from "./pages/Category/Service List/ServicesList.js";
import CategoriesRequestList from "./pages/Category/Requestlist/RequestList.js";

import SubCategoriesProduct from "./pages/SubCategories/Products/List.js";
import SubCategoriesServiceList from "./pages/SubCategories/Services/List.js";
// import CategoriesRequestList from "./pages/Category/Requestlist/RequestList.js";

import Support from "./pages/Support/Support.js";

import Inventory from "./pages/Inventory/List.js";
import RequestInventory from "./pages/Inventory/Request/List.js";

// import ServiceList from "./pages/Services/Grooming/List.js";
// import RequestService from "./pages/Services/Request/List.js";

import GroomingServiceList from "./pages/Services/Grooming/List.js";
import VeterinaryServiceList from "./pages/Services/Veterinary/List.js";
import TrainingServiceList from "./pages/Services/Training/List.js";
import BoardingServiceList from "./pages/Services/Boarding/List.js";
import DayCareServiceList from "./pages/Services/DayCare/List.js";

import RequestGroomingService from "./pages/Services/Request/Grooming/List.js";
import RequestVeterinaryService from "./pages/Services/Request/Veterinary/List.js";
import RequestTrainingService from "./pages/Services/Request/Training/List.js";
import RequestBoardingService from "./pages/Services/Request/Boarding/List.js";
import RequestAdoptionService from "./pages/Services/Request/Adoption/List.js";
import RequestDayCareService from "./pages/Services/Request/DayCare/List.js";
import BulkRequest from "./pages/Services/Request/BulkRequest/List.js";

import DogFriendlyService from "./pages/Services/dogFriendly/dogFriendlyList.js";
import Banner from "./pages/CMS/Banner/banner.js";
import Breeds from "./pages/CMS/Breeds/breeds.js";
import Blogs from "./pages/CMS/Blogs/blogs.js";
import AddEditBlog from "./pages/CMS/Blogs/addEditBlogs.js"

import Reasons from "./pages/CMS/Reasons/reason.js";
import DogAdoptionService from "./pages/Services/dogAdoption/dogAdoptionList.js";

import Insights from "./pages/Insights/List.js";
import Insurance from "./pages/Insurance/List.js";
import InsuranceDetails from "./pages/Insurance/InsuranceDetails.js";
import Inquery from "./pages/Inquiry/List.js";

import Profile from "./pages/BottomProfile/Profile.js";

// import Payments from "./pages/Payments/List.js";
// import PaymentsEditPage from "./pages/Payments/PaymentsEditpage.js";

import ShopPayments from "./pages/Payments/Shop/List.js";

import GroomingPayments from "./pages/Payments/Grooming/List.js";
import BoardingPayments from "./pages/Payments/Boarding/List.js";
import VeterinaryPayments from "./pages/Payments/Veterinary/List.js";
import TrainingPayments from "./pages/Payments/Training/List.js";
import DayCarePayments from "./pages/Payments/DayCare/List.js";

import Promtotions from "./pages/Promotions/List.js";
import PromtotionsRequest from "./pages/Promotions/PromoRequest/List.js";
import PromotionsEditPage from "./pages/Promotions/PromotionsEditPage.js";
import RatingAndReview from "./pages/Rating And Review/List.js";
import RatingAndReviewEditPage from "./pages/Rating And Review/RatingAndReviewEditPage.js";
import Message from "./pages/Message/Message.js";

import OrderList from "./pages/Orders/List.js";
import OrdersDetails from "./pages/Orders/OrdersDetails/OrdersDetails.js";
import StoreDetailsForm from "./pages/BottomProfile/StoreDetailsForm.js";
import Setiting from "./pages/SettingPage/Setiting.js";

// import BookingList from "./pages/Bookings/List.js";
// import BookingDetails from "./pages/Bookings/Booking Details/BookingDetails.js";

import GroomingList from "./pages/Bookings/Grooming/List.js";
import GroomingBookingDetails from "./pages/Bookings/Grooming/Booking Details/BookingDetails.js";

import VeterinaryList from "./pages/Bookings/Veterinary/List.js";
import VeterinaryBookingDetails from "./pages/Bookings/Veterinary/Booking Details/BookingDetails.js";

import TrainingList from "./pages/Bookings/Training/List.js";
import TrainingBookingDetails from "./pages/Bookings/Training/Booking Details/BookingDetails.js";

import BoardingList from "./pages/Bookings/Boarding/List.js";
import BoardingBookingDetails from "./pages/Bookings/Boarding/Booking Details/BookingDetails.js";

import DayCareList from "./pages/Bookings/DayCare/List.js";
import DayCareBookingDetails from "./pages/Bookings/DayCare/Booking Details/BookingDetails.js";

import AdminDashboardEditPage from "./pages/DashBaord/DashboardEditPage.js";

import ProductList from './pages/Products/Product List/ProductList.js'
import ProductListEditPage from "./pages/Products/Product List/ProductListEditPage.js";
import ProductBulkUpload from "./pages/Products/Product List/ProductBulkUpload.js";
import  TermAndCondition  from "./pages/CMS/TermAndCondition.js/TermAndCondition.js";
import PrivacyPolicy from "./pages/CMS/PrivacyPolicy/PrivacyPolicy.js";
import Treats from "./pages/CMS/Treats/Treats.js";
import GeneralData from "./pages/CMS/GeneralData/StoreDetailsForm.js";
import EmailTemplets from "./pages/CMS/EmailTempets/list.js";
import EmailTempletsAddEddit from "./pages/CMS/EmailTempets/AddEditPage.js";
import { useUserStore } from "./lib/userStore.js";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchUserInfo } = useUserStore();

  const getIp = async () => {
    await fetchIpAddress();
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      await getIp();
      const token = sessionStorage.getItem("TOKEN");

      if (!["/", "/login", "/reset-password"].includes(location?.pathname)) {
        if (!token) {
          navigate("/");
        } else {
          fetchUserInfo();
        }
      }
    };

    checkAuthentication();
  }, [location, navigate, fetchUserInfo]);

  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={ <Sidebar> <Dashboard /> </Sidebar> } />

          <Route path="/sub-admin/list" element={ <Sidebar> <SubAdminList /> </Sidebar> } />
          <Route path="/sub-admin/addeditdata" element={ <Sidebar> <SubAdminAddEditData /> </Sidebar> } />

          <Route path="/users/list" element={ <Sidebar> <UserList /> </Sidebar> } />
          <Route path="/users/addeditdata" element={ <Sidebar> <UserListEditPage /> </Sidebar> } />

          <Route path="/vendors/list" element={ <Sidebar> <VenoderList /> </Sidebar> } />
          <Route path="/vendors/addeditdata" element={ <Sidebar> <VendorListEditPage /> </Sidebar> } />
          
          <Route path="/store/list" element={ <Sidebar> <StoreList /> </Sidebar> } />
          <Route path="/store/addeditdata" element={ <Sidebar> <StoreAddEditPage /> </Sidebar> } />

          <Route path="/branch/list" element={ <Sidebar> <BranchList /> </Sidebar> } />
          {/* <Route path="/store/addeditdata" element={ <Sidebar> <StoreAddEditPage /> </Sidebar> } /> */}

          <Route path="/categories/product/list" element={ <Sidebar> <CategoriesProductList /> </Sidebar> } />
          <Route path="/categories/service/list" element={ <Sidebar> <CategoriesServiceList /> </Sidebar> } />
          <Route path="/categories/request/list" element={ <Sidebar> <CategoriesRequestList /> </Sidebar> } />

          <Route path="/subcategories/product/list" element={ <Sidebar> <SubCategoriesProduct /> </Sidebar> } />
          <Route path="/subcategories/service/list" element={ <Sidebar> <SubCategoriesServiceList /> </Sidebar> } />
          
          <Route path="/product/list" element={ <Sidebar> <ProductList /> </Sidebar> } />
          <Route path="/product/list/edit" element={ <Sidebar> <ProductListEditPage /> </Sidebar> } />
          <Route path="/product/bulk-upload" element={ <Sidebar> <ProductBulkUpload /> </Sidebar> } />
          
          <Route path="/order/list" element={ <Sidebar> <OrderList /> </Sidebar> } />
          <Route path="/order/details" element={ <Sidebar> <OrdersDetails /> </Sidebar> } />

          <Route path="/inventory/list" element={ <Sidebar> <Inventory /> </Sidebar> } />
          <Route path="/inventory/request-list" element={ <Sidebar> <RequestInventory /> </Sidebar> } />
          {/* Services Add/Edit and Listing */}
          {/* <Route path="/service/list" element={ <Sidebar> <ServiceList /> </Sidebar> } />
          <Route path="/service/request-list" element={ <Sidebar> <RequestService /> </Sidebar> } /> */}
          {/* Grooming Services */}
          <Route path="/service/grooming/list" element={ <Sidebar> <GroomingServiceList /> </Sidebar> } />
          <Route path="/service/veterinary/list" element={ <Sidebar> <VeterinaryServiceList /> </Sidebar> } />
          <Route path="/service/training/list" element={ <Sidebar> <TrainingServiceList /> </Sidebar> } />
          <Route path="/service/boarding/list" element={ <Sidebar> <BoardingServiceList /> </Sidebar> } />
          <Route path="/service/daycare/list" element={ <Sidebar> <DayCareServiceList /> </Sidebar> } />


          <Route path="/service/grooming/request-list" element={ <Sidebar> <RequestGroomingService /> </Sidebar> } />
          <Route path="/service/veterinary/request-list" element={ <Sidebar> <RequestVeterinaryService /> </Sidebar> } />
          <Route path="/service/training/request-list" element={ <Sidebar> <RequestTrainingService /> </Sidebar> } />
          <Route path="/service/boarding/request-list" element={ <Sidebar> <RequestBoardingService /> </Sidebar> } />
          <Route path="/service/adoption/request-list" element={ <Sidebar> <RequestAdoptionService /> </Sidebar> } />
          <Route path="/service/day-care/request-list" element={ <Sidebar> <RequestDayCareService /> </Sidebar> } />
          <Route path="/service/bulk-request/list" element={ <Sidebar> <BulkRequest /> </Sidebar> } />
          
          <Route path="/service/dog-friendly" element={ <Sidebar> <DogFriendlyService /> </Sidebar> } />
          <Route path="/service/dog-adoption" element={ <Sidebar> <DogAdoptionService /> </Sidebar> } />
          
          {/* CMS */}
          <Route path="/cms/banner" element={ <Sidebar> <Banner/> </Sidebar> } />
          <Route path="/cms/term-condition" element={<Sidebar> <TermAndCondition/> </Sidebar> } />
          <Route path="/cms/privacy-policy" element={ <Sidebar> <PrivacyPolicy/> </Sidebar> } />
          <Route path="/cms/treats" element={ <Sidebar> <Treats/> </Sidebar> } />
          <Route path="/cms/general-data" element={ <Sidebar> {" "} <GeneralData />{" "} </Sidebar> } />
          <Route path="/cms/email-templates" element={ <Sidebar> {" "} <EmailTemplets />{" "} </Sidebar> } />
          <Route path="/cms/email-templates/addeditdata" element={ <Sidebar> {" "} <EmailTempletsAddEddit />{" "} </Sidebar> } />
	        <Route path="/cms/reasons" element={<Sidebar> <Reasons/> </Sidebar>} />
          <Route
          path="/cms/breeds"
          element={
            <Sidebar>
              {" "}
              <Breeds />{" "}
            </Sidebar>
          }
        />
                  <Route
          path="/cms/blogs"
          element={
            <Sidebar>
              {" "}
              <Blogs />{" "}
            </Sidebar>
          }

        />
        <Route path="/cms/blogs/addeditdata" element={<Sidebar> <AddEditBlog/> </Sidebar> } />

        
         
          {/* Grooming Bookings */}
	        <Route path="/bookings/grooming/list" element={ <Sidebar> <GroomingList /> </Sidebar> } />
          <Route path="/bookings/grooming/details" element={ <Sidebar> <GroomingBookingDetails /> </Sidebar> } />

          {/* Veterinary Bookings */}
	        <Route path="/bookings/veterinary/list" element={ <Sidebar> <VeterinaryList /> </Sidebar> } />
          <Route path="/bookings/veterinary/details" element={ <Sidebar> <VeterinaryBookingDetails /> </Sidebar> } />
          
          {/* Training Bookings */}
	        <Route path="/bookings/training/list" element={ <Sidebar> <TrainingList /> </Sidebar> } />
          <Route path="/bookings/training/details" element={ <Sidebar> <TrainingBookingDetails /> </Sidebar> } />

          {/* Boarding Bookings */}
	        <Route path="/bookings/boarding/list" element={ <Sidebar> <BoardingList /> </Sidebar> } />
          <Route path="/bookings/boarding/details" element={ <Sidebar> <BoardingBookingDetails /> </Sidebar> } />

          {/* Day Care Bookings */}
	        <Route path="/bookings/day-care/list" element={ <Sidebar> <DayCareList /> </Sidebar> } />
          <Route path="/bookings/day-care/details" element={ <Sidebar> <DayCareBookingDetails /> </Sidebar> } />

          <Route path="/insights/list" element={ <Sidebar> <Insights /> </Sidebar> } />
          <Route path="/insurance/list" element={ <Sidebar> <Insurance /> </Sidebar> } />
          <Route path="/insurance/details" element={ <Sidebar> <InsuranceDetails /> </Sidebar> } />

          {/* <Route path="/payments" element={ <Sidebar> <Payments /> </Sidebar> } /> */}

          <Route path="/payments/shop/list" element={ <Sidebar> <ShopPayments /> </Sidebar> } />
          <Route path="/payments/grooming/list" element={ <Sidebar> <GroomingPayments /> </Sidebar> } />
          <Route path="/payments/boarding/list" element={ <Sidebar> <BoardingPayments /> </Sidebar> } />
          <Route path="/payments/veterinary/list" element={ <Sidebar> <VeterinaryPayments /> </Sidebar> } />
          <Route path="/payments/training/list" element={ <Sidebar> <TrainingPayments /> </Sidebar> } />
          <Route path="/payments/day-care/list" element={ <Sidebar> <DayCarePayments /> </Sidebar> } />

          <Route
            path="/profile"
            element={
              <Sidebar>
                <Profile />
              </Sidebar>
            }
          />
          
         
          {/* <Route
            path="/paymentsEditPage"
            element={
              <Sidebar>
                <PaymentsEditPage />
              </Sidebar>
            }
          /> */}

          <Route
            path="/promtotions/request-list"
            element={
              <Sidebar>
                <PromtotionsRequest />
              </Sidebar>
            }
          />
          <Route
            path="/promtotions"
            element={
              <Sidebar>
                <Promtotions />
              </Sidebar>
            }
          />
          <Route
            path="/promotionsEditPage"
            element={
              <Sidebar>
                <PromotionsEditPage />
              </Sidebar>
            }
          />
          
          <Route path="/rating-review" element={ <Sidebar> <RatingAndReview /> </Sidebar> }

          />
          <Route
            path="/ratingAndReviewEditPage"
            element={
              <Sidebar>
                <RatingAndReviewEditPage />
              </Sidebar>
            }
          />
          <Route
            path="/message"
            element={
              <Sidebar>
                <Message />
              </Sidebar>
            }
          />

          <Route path="/faqs" element={ <Sidebar> <Support /> </Sidebar> } />
          <Route path="/support/list" element={<Sidebar> {" "} <Inquery />{" "} </Sidebar>} />

          <Route
            path="/storeDetailsForm"
            element={
              <Sidebar>
                <StoreDetailsForm />
              </Sidebar>
            }
          />
          <Route
            path="/notifications"
            element={
              <Sidebar>
                <Notifications />
              </Sidebar>
            }
          />
          <Route
            path="/setiting"
            element={
              <Sidebar>
                <Setiting />
              </Sidebar>
            }
          />
          
          
          <Route
            path="/adminDashboardEditPage"
            element={
              <Sidebar>
                <AdminDashboardEditPage />
              </Sidebar>
            }
          />
        </Routes>
      
    </div>
  );
};

export default App;
