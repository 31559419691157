import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useRef } from "react";
import {
  Dropdown,
  Menu,
  Switch,
  notification,
  Drawer,
  Button,
  Space,
} from "antd";
import { FaEdit, FaPlusSquare, FaTrashRestore, FaRegCopy, FaFilter } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../DashBaord/Top_navbar";
import UserListDeletePopUp from "./ProductListDeletePopUp";
import { Link, useNavigate } from "react-router-dom";
import { chnageStatus, list } from "../../../controllers/Products/product";
import { ASSETS_BASE_URL } from "../../../config/constants";
import moment from "moment";
import { getPage } from "../../../controllers/common";
import ProductListDeletePopUp from "./ProductListDeletePopUp";
import Skeltonlist from "../../../components/SkeltonEffect/list";
function ProductList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ProductList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const targetRef = useRef(null);
  const navigate = useNavigate();
  // delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [EDITDATA, setEditData] = useState([]);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const handleAddEditData = () => {
    navigate("/product/list/edit", { state: EDITDATA });
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  };

  /*********************************************************
   *  This function is use to handle notification
   *********************************************************/
  const handleNotification = (status, msg) => {
    notification.open({
      message: status,
      description: msg,
      placement: "topRight",
      duration: 2,
    });
  }; //End
  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const [isLoading, setIsLoading] =useState(true);
  const [deteteId, setDeteteId] = useState("");
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End
  
  const [copySuccess, setCopySuccess] = useState('');
  const [showRequest, setShowRequest] = useState("");
  const getList = async () => {
    setListData([]);
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(showRequest ? { status: showRequest } : {status : {$ne : 'D'}}),
      },
      ...(filter ? { filter: filter } : null),
      select: {},
      sort: { _id: -1 },
      populate: {
        key: "category subcategory",
        select: "name",
      },
      skip: SKIP ? SKIP : 0,
      limit: LIMIT ? LIMIT : 10,
    };
    const listData = await list(options);
    if (listData.status === true) {
      setListData(listData?.result);
      setIsLoading(false);
      const countOptions = {
        type: "count",
        condition: { ...(showRequest ? { status: showRequest } : null) },
        ...(filter ? { filter: filter } : null),
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result));
    } else {
      setListData([]);
      setIsLoading(false);
    }
  }; //End
  
  /*********************************************************
   *  This function is use to handle chnage status
   *********************************************************/
  const handleStatus = async (item) => {
    const option = {
      url: `poductsmaster/change-status`,
      postData: {
        id: item?._id,
        status: item.status === "A" ? "I" : "A",
      },
    };
    const res = await chnageStatus(option);
    if (res.status === true) {
      getList();
      handleNotification("Success!", "Status Updated.");
    } else {
      handleNotification("Error!", res?.message);
    }
  }; //End

  
  /*********************************************************
   *  This function is use to handle restore
   *********************************************************/
  const handleRestore = async (item_id) => {
    if(item_id){
      const option = {
        url: `poductsmaster/change-status`,
        postData: {
          id: item_id,
          status: "A",
        },
      };
      const res = await chnageStatus(option);
      if (res.status === true) {
        getList();
        handleNotification("Success!", "Status Updated.");
      } else {
        handleNotification("Error!", res?.message);
      }
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter("");
      inputRef1.current.value = "";
      inputRef2.current.value = "";
      inputRef3.current.value = "";
      inputRef4.current.value = "";
    } catch (error) {}
  };

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        filter_by: form.get("filter_by") || "",
        search: form.get("search") || "",
        to: form.get("to_date") || "",
        from: form.get("from_date") || "",
      }));

      handleFiterDrawer();
    } catch (error) {}
  };

  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
    document.title = "Frisbee Super Admin || Product List";
  }, [currentPage, isFilterShow, filter, showRequest, popUpOpen]);

  const copyToClipboard = (seq_id) => {
    navigator.clipboard.writeText(seq_id).then(() => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
    }, (err) => {
        setCopySuccess('Failed to copy!');
        console.error('Failed to copy text: ', err);
    });
  };

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={`Product List`}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      onClick={()=>setShowRequest('')}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Deleted"
                      className="tabs_main_contnet"
                      onClick={()=>setShowRequest('D')}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </div>

                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div
                    className="inventory_filter_div"
                    onClick={handleFiterDrawer}
                  >
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt="filter"
                      className="user_iconses"
                    />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                  {/* <div onClick={() => handleEdit()}> */}
                  <button
                    type="button"
                    class="btn btn-dark"
                    onClick={() => {
                      navigate("/product/list/edit");
                    }}
                  >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">Add New</span>
                  </button>

                  <button
                    type="button"
                    class="btn btn-dark"
                    onClick={() => {
                      navigate("/product/bulk-upload");
                    }}
                  >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">Import</span>
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <ProductList value={value} index={0}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Product List
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>Image {filter?.filter_by === 'name'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Category {filter?.filter_by === 'category'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Sub Category {filter?.from === 'subcategory'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Date&Time {filter?.from?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Status {filter?.filter_by === 'status'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    
                                    <td title="Click to copy" onClick={()=>copyToClipboard(items?._id)}>
                                      {/* {String(items?.seq_id).substr(11)}  */}
                                      {copySuccess ? <><br/><span style={{ marginLeft: '5px', color:'blue' }}>{copySuccess}</span></>:<FaRegCopy/>}
                                    </td>
                                    <td>
                                      <div className="admin_prodcut_list_spanotherimg">
                                        <img
                                          src={`${ASSETS_BASE_URL}${items.image}`}
                                          alt=""
                                        />
                                        <span className="admin_prodcut_list_span_name">
                                          {items?.name}
                                        </span>
                                      </div>
                                    </td>

                                    <td className="admin_user_list_date">
                                      {items.category.name}{" "}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items.subcategory.name}
                                    </td>

                                    <td className="admin_user_list_date">
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td>
                                      <Switch
                                        className="categories_switch_"
                                        checked={
                                          items?.status === "A" ? true : false
                                        }
                                        onClick={() => handleStatus(items)}
                                      />
                                    </td>
                                    <td>
                                      <Dropdown
                                        // overlay={dropdownMenu}
                                        overlay={
                                          <Menu>
                                            <Menu.Item key="1">
                                              <FaEdit />
                                              <span
                                                className="show_span_edit"
                                                onClick={handleAddEditData}
                                              >
                                                Edit Product List
                                              </span>
                                            </Menu.Item>
                                            <Menu.Item
                                              key="2"
                                              onClick={() => {
                                                setDeteteId(items._id);
                                                togglePopUp();
                                              }}
                                            >
                                              <MdDelete />{" "}
                                              <span className="show_span">
                                                Delete
                                              </span>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                        placement="bottomLeft"
                                        arrow
                                        onClick={() => setEditData(items)}
                                      >
                                        <HiOutlineDotsHorizontal />
                                      </Dropdown>
                                    </td>
                                  </tr>
                              </>))) : isLoading ? <Skeltonlist row={5} col={7} /> : <tr> 
                                <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ProductList>
            {/* Third Inventory */}
            <ProductList value={value} index={1}>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 all_border_bottom_for_hide">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Product List
                      </h6>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image {filter?.filter_by === 'name'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Category {filter?.filter_by === 'category'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Sub Category {filter?.from === 'subcategory'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Date&Time {filter?.from?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Status {filter?.filter_by === 'status'?(<FaFilter style={{color : '#e1251b'}}/>):''}</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    {/* <td>{String(items.seq_id).substr(11)}</td> */}
                                    <td>
                                      <div className="admin_prodcut_list_spanotherimg">
                                        <img
                                          src={`${ASSETS_BASE_URL}${items.image}`}
                                          alt=""
                                        />
                                        <span className="admin_prodcut_list_span_name">
                                          {items?.name}
                                        </span>
                                      </div>
                                    </td>

                                    <td className="admin_user_list_date">
                                      {items.category.name}{" "}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items.subcategory.name}
                                    </td>

                                    <td className="admin_user_list_date">
                                      {moment(`${items.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td>Deleted</td>
                                    <td>
                                      <button className="btn btn-success" onClick={()=>handleRestore(items._id)}>
                                        <FaTrashRestore /> Restore
                                      </button>
                                    </td>
                                  </tr>
                                </>))) : isLoading ? <Skeltonlist row={5} col={6} /> : <tr> 
                                <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ProductList>
          </Box>
        </div>
        <ProductListDeletePopUp
          popUpOpen={popUpOpen}
          deleteId={deteteId}
          togglePopUp={togglePopUp}
        />
        {/* Filter */}
        <Drawer
          title="Product Filter"
          placement={`right`}
          width={500}
          onClose={handleFiterDrawer}
          open={isFilterShow}
          extra={
            <Space>
              <Button onClick={handleFiterDrawer}>Cancel</Button>
              <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }} >
                Reset
              </Button>
            </Space>
          }
        >
          <div className="responsive">
            <div className="row">
              <form id="filter_form" onSubmit={handleFilterApply}>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_section_for_label_main_class"
                  >
                    Search Field :
                  </label>
                  <select
                    name="filter_by"
                    className="form-control store_input_field"
                    id="filter_by"
                    ref={inputRef1}
                  >
                    <option value="">Select search field</option>
                    <option
                      value="name"
                      selected={filter?.filter_by === "name" ? true : false}
                    >
                      Name
                    </option>
                    <option
                      value="category"
                      selected={filter?.filter_by === "category" ? true : false}
                    >
                      Category
                    </option>
                    <option
                      value="subcategory"
                      selected={filter?.filter_by === "subcategory" ? true : false}
                    >
                      Sub Category
                    </option>
                    <option
                      value="status"
                      selected={filter?.filter_by === "status" ? true : false}
                    >
                      Status
                    </option>
                  </select>
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_section_for_label_main_class"
                  >
                    Search Text :
                  </label>
                  <input
                    title="Enter search text."
                    placeholder="Enter search text."
                    type="text"
                    name="search"
                    className="form-control store_input_field"
                    id="search"
                    ref={inputRef2}
                    // onChange={handleFilterChnage}
                    // value={filter?.search}
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_section_for_label_main_class"
                  >
                    From :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="from_date"
                    className="form-control store_input_field"
                    id="from_date"
                    ref={inputRef3}
                    // onChange={handleFilterChnage}
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label
                    htmlFor="vendor_first_name"
                    className="all_section_for_label_main_class"
                  >
                    To :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="to_date"
                    className="form-control store_input_field"
                    id="to_date"
                    ref={inputRef4}
                    // onChange={handleFilterChnage}
                  />
                </div>
                <br />
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button
                    title="Enter search text."
                    type="submit"
                    className="form-control btn btn-black"
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}
