import "./main_dashboard.css";
import { CiSearch } from "react-icons/ci";
import { LuBell, LuBellDot } from "react-icons/lu";
import { Dropdown } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import LogoutConformationPopup from "./LogoutConformationPopup";

import NotificationDrawer from './NotificationDrawer'
import { NotificationsList, deleteNotifications } from "../../controllers/notifications/notificationsControllers";

const Top_navbar = ({title = "Dashboard"}) => {
  const [isSticky, setSticky] = useState(false);
  const [showNotification, setShowNotification] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //open my dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigate = useNavigate();
  const handleLogoutClick = () => {
    navigate("/");
  };

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [UNREADNOTIFICATION, setUnreadNotification] = useState('')
  const handleNotificationShow = () => {
    try {
      getUnreadNotification();
      setShowNotification(!showNotification);
    } catch (error) {
      
    }
  }

  const getUnreadNotification = async () => {
    try {
      const options = {
        condition : {status : "U"},
        limit : 10
      }
      const res = await NotificationsList(options);
      if(res.status === true){
        setUnreadNotification(res?.result);
      } else{
        setUnreadNotification({message : 'No Data Found.'});
      }
    } catch (error) {
      
    }
  }

  return (
    <>
      <div className={isSticky ? "sticky" : ""}>
        <div className="container_fluid">
          <div className="row">
            <div className="col-md-6">
              <h1 className="main_heading">{title}</h1>
            </div>
            <div className="col-md-4">
              {/* <div className="search_box">
                <input
                  type="text"
                  placeholder="Search.."
                  className="search_input"
                ></input>
                <CiSearch id="search_icon" />
              </div> */}
            </div>
            <div className="col-md-1 main_dashboard_tooltip">
              <div className="col">
                <div>
                  <button className="btn_icon" onClick={handleNotificationShow}>
                    <LuBellDot />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-1 main_dashboard_tooltip">
              <div className="col">
                <Dropdown show={isDropdownOpen} onToggle={toggleDropdown}>
                  <div className="top_nav_oo">
                    <Dropdown.Toggle id="dropdown-basic" className="btn_icon">
                      <RiBarChartHorizontalLine />
                    </Dropdown.Toggle>
                  </div>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      className="admin_dashboard_dropdwon top_nav_profile"
                    >
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400 "></i>
                     <Link to="/profile"> Profile</Link>
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      href="#"
                      className="admin_dashboard_dropdwon"
                    >
                      <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                      Activity Log
                    </Dropdown.Item> */}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={togglePopUp}
                      href="#"
                      data-toggle="modal"
                      data-target="#logoutModal"
                      className="admin_dashboard_dropdwon"
                    >
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        </div>
      <LogoutConformationPopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
      <NotificationDrawer 
        drawerOpen={showNotification}  
        RequesttoggleDrawer = {handleNotificationShow}
        notificationsList = {UNREADNOTIFICATION}
      />
    </>
  );
};
export default Top_navbar;
