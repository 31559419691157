import { API_ADMIN_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get list of all inventory list
* By            : Afsar Ali
* Date          : 08-03-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/inventory-list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : requestList
* Description   : Get list of all request list
* By            : Afsar Ali
* Date          : 08-03-2024 
*********************************************************/
export const requestList = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/request/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : addEditInventory
* Description   : This function is used for Add/Edit Inventory
* By            : Afsar Ali
* Date          : 29-03-2024 
*********************************************************/
export const addEditInventory = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/addedit-inventory`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : "success"};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : requestChnageStatus
* Description   : This function is used for chnage product request status
* By            : Afsar Ali
* Date          : 29-03-2024 
*********************************************************/
export const requestChnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/request/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : "success"};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function
/*********************************************************
* Function Name : addQuantity
* Description   : This function is used for chnage product request status
* By            : Noor Alam
* Date          : 23-04-2024 
*********************************************************/
export const addQuantity = async (options) =>{
    // console.log("Alam options",options);
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/add-quantity`,
            postData : {
                ...options
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : "success"};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : addQuantity
* Description   : This function is used for chnage product request status
* By            : Noor Alam
* Date          : 23-04-2024 
*********************************************************/
export const updateVarient = async (options) =>{
    // console.log("Alam options",options);
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/update-inventory`,
            postData : {
                ...options
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : "success"};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : bulkUpload
* Description   : This function is used for bulk upload
* By            : Afsar Ali
* Date          : 07-08-2024 
*********************************************************/
export const bulkUpload = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/bulk-inventory-upload`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : "success"};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : varientChnageStatus
* Description   : This function is used for chnage inventory request status
* By            : AFsar Ali
* Date          : 08-08-2024 
*********************************************************/
export const varientChnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}poductsmaster/inventory-chnage-status`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : "success"};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function