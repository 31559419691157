import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification, Progress, Upload, Flex, Button } from "antd";
import { CheckCircleTwoTone, CloseOutlined, } from "@ant-design/icons";

import { FaFileUpload } from "react-icons/fa";
import "../ServiceList.css";
import ExcelJS from 'exceljs';
import {ucfirst, commonList} from '../../../controllers/common';
import { blukUpload } from "../../../controllers/services/dogFriendly"
import { vendorListByService as vendorlist} from "../../../controllers/vendors/vendorsController"
import { list as serviceList } from "../../../controllers/categories/services"
import { Link } from "react-router-dom";
import { ASSETS_BASE_URL } from '../../../config/constants';
const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7',
};
const ProductListAddEdit = ({ show, setBulkUploadPopUp }) => {
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };
  /*********************************************************
  *  This function is use to handle error notification show
  *********************************************************/
  const handleErrorMsg = (msg="") => {
    notification.open({
      message: "Opps!",
      description: error?.formError || msg,
      placement: "topRight",
      duration : 2,
      icon: <CloseOutlined  style={{color : "red"}} />,
    });
  };//End
  const [isLoading, setIsLoading] = useState(false);
  const [error, serError] = useState([]);
  const [success, serSuccess] = useState([]);
  const [formData, setFormData] = useState("");
  const [imageList, setImageList] = useState("");
  const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [ALLCATEGORY, setAllCategory] = useState([]);
  const [currentService, setCurrentService] = useState(''); 
  const [jsonData, setJsonData] = useState(null);
  const [progress, setProgress] = useState(0);
  /*********************************************************
  *  This function is use to handle cancle button and close popup
  *********************************************************/
  const handleCancel = () => {
    setFormData("");
    setImageList("");
    setBulkUploadPopUp(false);
    setJsonData('')
  };
  
  /*********************************************************
  *  This function is use to handle image object
  *********************************************************/
  const handleImageChnage = (e) => {
    setImageList(e.fileList[0].originFileObj);
    const file = e.fileList[0].originFileObj;
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rows = [];

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values.slice(1); // Remove the first empty value
        rows.push(rowValues);
      });

      // Convert rows to JSON (assuming first row is the header)
      const headers = rows[0];
      const data = rows.slice(1).map(row => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });
      setJsonData(data);
    };

    reader.readAsArrayBuffer(file);
  }
  /*********************************************************
  *  This function is use to handle form submit
  *********************************************************/
  const handleSubmit = async (e)=>{
    e.preventDefault();
    if(jsonData && jsonData?.length === 0){
      handleErrorMsg('No data found in file.');
    } else{
      const dataArray = await transformBulkData(jsonData);
      
      if(dataArray && dataArray.length > 0){
        if (dataArray && dataArray?.length > 0) {
          for (let i = 0; i < dataArray.length; i += 10) {
              const batch = dataArray.slice(i, i + 10);
              const res = await blukUpload(batch);
              if (res.status !== true) {
                  notification.open({
                    message: "Oops!!",
                    description: `Error while uploading`,
                    placement: "topRight",
                    icon: <CheckCircleTwoTone twoToneColor="red" />,
                  });
                  break;
              }
              const progressPercentage = Math.round(((i + 10) / dataArray.length) * 100);
              setProgress(progressPercentage);
          }
          notification.open({
            message: "Success",
            description: "Upload Successfully.",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="green" />,
          });
          handleCancel();
        }
      } else{
        handleErrorMsg('No data found in file.');
      }
    }
  } //End

  const transformBulkData = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result = [];
            for (let i = 0; i < data.length; i += 10) {
                const batch = data.slice(i, i + 10);
                const transformedBatch = await Promise.all(batch.map(async (item) => {
                    return {
                      title: item?.title || '',
                      image : item?.image.text,
                      about : item?.about || '',
                      ratings : item?.ratings || '',
                      phone : item?.phone || '',
                      latitude : item?.latitude,
                      longitude : item?.longitude,
                      parks : item?.parks,
                      restaurant : item?.restaurant,
                      cafe : item?.cafe || item?.café,
                      beach : item?.beach,
                      center_facilities : item?.center_facilities.split(','),
                      status : 'A'
                    };
                }));
                result = result.concat(transformedBatch);
            }
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
  };
  

  useEffect(()=>{
    setProgress(0);
    setJsonData('')
  },[show]);
  return ( 
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="categories_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="categories_popup_title_heading"
            >
              Bulk Upload Service
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <div class="form-group row">
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data inventory_upload_excel_section">
                      <label htmlFor="company_registration_document" className="all_lable_for_vendor_dashbaord">
                        Upload excel
                      </label>
                      <div
                        className="sign_up_file_upload_for_inventory"
                        id="company_registration_document"
                      >
                        <div className="company_registration_document_button">{imageList?.name ? imageList.name : 'Upload File'}</div>
                        <Upload 
                          id="company_registration_document"
                          fileList={[]}
                          onChange={handleImageChnage}
                        >
                          <Button icon={<FaFileUpload />}>{imageList?.name ? `Change` : 'Upload File'}</Button>
                        </Upload>
                      </div>
                        <Link to={`${ASSETS_BASE_URL}assets/friendly-bulk-upload.xlsx`} className="bulk_upload_text" target="_blank">Download template</Link>
                    </div>
                  </div>
                  {progress === 0 ? (
                  <div className="product_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-danger "
                    >
                      Submit
                    </button>
                  </div>
                 ):(
                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data inventory_upload_excel_section">
                    <Progress type="line" percent={progress} strokeColor={conicColors} />
                  </div>
                 )}
                </form>
              </div>
            </div>
          </Typography>
          {/* <Loading
            isLoading={isLoading}
          /> */}
        </div>
      </div>
    </Dialog>
  );
};

export default ProductListAddEdit;
