import { API_ADMIN_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : createStore for dog friendly services
* Description   : This function is used forcreateStore for dog friendly services
* By            : Noor Alam
* Date          : 25April-2024 
*********************************************************/
export const createStore = async (options) => {
    try{
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/dog-frindlly/create`,
            postData: options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status: true, result : res?.data?.response?.result};
        }else{
            return {status: false, message:res?.response?.data?.statusMessage}
        }
    }catch(error){
        console.log(error);
        return {status: false, message: "Under Maintenance, please try after some time."}
    }
}



/*********************************************************
* Function Name : createStoreList
* Description   : This function is used for createStoreList for dog Friendly
* By            : Noor Alam
* Date          : 25April-2024 
*********************************************************/
export const list = async (options) => {
    try{
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/dog-frindlly/list`,
            postData: options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status: true, result : res?.data?.response?.result};
        }else{
            return {status: false, message:res?.response?.data?.statusMessage}
        }
    }catch(error){
        console.log(error);
        return {status: false, message: "Under Maintenance, please try after some time."}
    }
}



/*********************************************************
* Function Name : chnageStatus
* Description   : This function is used for change status for dog Friendly
* By            :  Noor Alam
* Date          : 25April-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/dog-frindlly/change-status`,
            postData : {
                ...options,
                ipAddress : await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : blukUpload
* Description   : This function is used for bulk upload day care service
* By            : Afsar Ali
* Date          : 09-08-2024 
*********************************************************/
export const blukUpload = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}servicesmaster/dog-frindllye/bluk-upload`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function