import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import "./Support.css";
import { Input, Space, Skeleton } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import Top_navbar from "../DashBaord/Top_navbar.js";

import { list } from "../../controllers/cms/faqs.js";


const { Search } = Input;
const Support = () => {
  const targetRef = useRef();
  const [ALLLISTDATA, setListData] = useState([]);
  const [searchValue, setSearchValue] = useState(""); 
  const [isSearch, setIsSearch] = useState(false);
  const handleSearch = () =>{
    setIsSearch(!isSearch);
  }
  const handleChange = async (e) => {
    
    if (e.key === "Enter") {
      handleSearch();
    } else{
      setSearchValue(e.target.value);
    }
  }
  /*********************************************************
  *  This function is use to fetch category list
  *********************************************************/
  const getList = async (source)=>{
    setListData([]);
    const options = {
      condition : {
        ...(searchValue?{ title : { $regex: searchValue, $options: 'i' } }:null)
       },
      select    : {  },
      sort      : {"_id" : -1}
    }
    const listData = await list(options,source);
    if(listData.status === true){
      if(listData?.result.length > 0){
        setListData(listData?.result);
      }else{
        setListData([{
          title : "No Data Found"
        }]);  
      }
    }else{
      setListData([{
        title : "No Data Found"
      }]);
    }
  };//End

  useEffect(()=>{
    const source = axios.CancelToken.source();
   getList(source);
   targetRef.current.scrollIntoView({
     behavior: 'smooth',
   });
    document.title = 'Frisbee Super Admin || FAQs'
   return () => {
     source.cancel('Request canceled due to component unmount or page change');
    };
  },[isSearch]);
  return (
    <div ref={targetRef}>
      <div className="container-fluid">
        <Top_navbar title={"Support & FAQs"}></Top_navbar>
        <div className="support_main_container">
          <div className="support_need_help">
            <span>Need Help?</span>
          </div>
          <div className="support_main_content">
            <div className="support_search_bar_main_div">
              <Search 
                name="search"
                allowClear 
                enterButton={ 
                  <Space onClick={handleSearch} onKeyDown={handleSearch}> 
                    <SearchOutlined className="support_main_class"  /> 
                  </Space> }
                className="support_main_class"
                size="large"
                onChange={handleChange}
              />
            </div>
            <div className="card shadow mb-4 support_Accordian_main_div">
              <div>
                <h6 className="m-0 support_promotions_heading">
                  Frequently Asked Question (FAQs)
                </h6>
              </div>
              <div className="support_accordian_div">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {ALLLISTDATA.length > 0 ? (ALLLISTDATA.map((items, index)=>(<>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id={`flush-heading${index}`}>
                        <button
                          class="accordion-button collapsed support_accordian_question"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}`}
                        >
                          {items?.title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index}`}
                        class="accordion-collapse collapse support_accordian_question"
                        aria-labelledby={`flush-heading${index}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          {items?.description}
                        </div>
                      </div>
                    </div>

                  </>))) : (<Skeleton loading={true} active paragraph={{ rows: 10 }} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
