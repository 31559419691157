import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Menu } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import SkeltonList from "../../components/SkeltonEffect/list";
import moment from "moment";
import { orderStatus } from '../../controllers/common';
import Pagination from "@mui/material/Pagination";

function OrderMainContent(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

OrderMainContent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({orderList, isLoading}) => {  
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  /********************** Integratin Stare Here **********************/
  const targetRef = useRef(null);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [showData, setShowData] = useState('shop');
  const [ALLLISTDATA, setAllListData] = useState([]);
  const handlechnage = (val) => {
    setShowData(val);
  }

  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
   const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const processData = async () => {
    if(orderList[showData] && orderList[showData].length > 10){
      const result = await splitArrayIntoBatches(orderList[showData], 10);
      setAllListData(result);
      const totalCount = orderList[showData].length;
      let page = Math.ceil(totalCount / 10);
      setTotalPages(page);
    } else{
      setAllListData([orderList]);
      setTotalPages(1);
    }
  }

  useEffect(()=>{
    processData();
  },[showData, isLoading])

  const splitArrayIntoBatches = async (arr, batchSize) => {
    return new Promise((resolve, reject) => {
      try {
        const batches = [];
        for (let i = 0; i < arr.length; i += batchSize) {
          const batch = arr.slice(i, i + batchSize);
          batches.push(batch);
        }
        resolve(batches);
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      <div className="inventory_tabs_main_div mt-0" ref={targetRef}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="inventory_all_tabs">
              <div className="all_tabs_value_rating_review">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="item_tabs_main_container"
                >
                  <Tab
                    label="Shop"
                    className="tabs_main_contnet"
                    {...a11yProps(0)}
                    onClick={()=>handlechnage('shop')}
                  />
                  <Tab
                    label="Grooming"
                    className="tabs_main_contnet"
                    {...a11yProps(1)}
                    onClick={()=>handlechnage('grooming')}
                  />
                  <Tab
                    label="Veterinary"
                    className="tabs_main_contnet"
                    {...a11yProps(1)}
                    onClick={()=>handlechnage('veterinary')}
                  />
                  <Tab
                    label="Training"
                    className="tabs_main_contnet"
                    {...a11yProps(1)}
                    onClick={()=>handlechnage('training')}
                  />
                  <Tab
                    label="Day Care"
                    className="tabs_main_contnet"
                    {...a11yProps(1)}
                    onClick={()=>handlechnage('daycare')}
                  />
                </Tabs>
              </div>
            </div>
          </Box>
          {/* First Inventory */}
          <OrderMainContent
            className="main_container_payments"
          >
            <div>
              <div id="content-wrapper" className="d-flex flex-column">
                <div className="card mb-4">
                  <div className="card-header py-3 all_border_bottom_for_hide">
                    <h6 className="m-0 font-weight-bold common_inventory_heading">
                      Recent Orders
                    </h6>
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Order Id</th>
                            <th>Store</th>
                            <th>Date</th>
                            <th>Products/Service</th>
                            <th>Contact No.</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ALLLISTDATA[currentPage - 1]?.length > 0 ? (ALLLISTDATA[currentPage - 1].map((items, index)=>(<>
                            
                          <tr key={index}>
                            <td>{items?.order_seq_id || items?.booking_seq_id}</td>
                            <td>{items?.shopId?.shop_name}</td>
                            <td>{moment(`${items?.createdAt}`).format('DD/MM/YYYY, h:m A')}</td>
                            <td>
                              {items?.orderdetails?.[0]?.productId
                                ? `${items.orderdetails.length} Item${items.orderdetails.length > 1 ? 's' : ''}`
                                : items?.bookingdetails?.[0]?.services?.name}
                            </td>
                            <td>{items?.userData?.country_code} {items?.userData?.phone}</td>
                            <td dangerouslySetInnerHTML={{__html : orderStatus(items.status)}}></td>
                            
                          </tr>
                          </>))) : isLoading ? <SkeltonList row={10} col={6} /> : <tr> 
                              <td colspan="5" className="img-center" ><img src='https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg' width='55%'/></td> 
                            </tr>}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                      <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OrderMainContent>
        </Box>
      </div>
    </>
  );
}

export default BasicTabs;
